import "./App.css";
import { NumberInput } from "./components/NumberInput";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  AisError,
  Answer1,
  Answer2,
  Answer3,
  Answer4,
  Answer5,
  NoEvidence,
} from "./components/AnswerCases";
import { Overlay } from "./components/Overlay";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { config } from "./config";

// import {
//   answer1_caseWithSameMid,
//   answer2_caseWithSameSas,
//   answer3_caseWithSasAndMid,
//   answer4_caseWithDifferentMid,
//   answer5_caseWithDifferentSas,
//   noEvidence,
//   pinBlocked,
// } from "./testCases";

function App() {
  const { t } = useTranslation();

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [showAnswer, setShowAnswer] = useState("");
  const [result, setResult] = useState<any>();
  const [iframe, setIframe] = useState(null);
  const [loadingAssurance, setLoadingAssurance] = useState<boolean | undefined>(
    undefined
  );

  const [asyncResponseId, setAsyncResponseId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [errorExist, setError] = useState(false);

  useEffect(() => {
    if (asyncResponseId) {
      const handler = (event) => {
        if (event?.data === "sas-success") {
          setIframe(null);
          setLoadingAssurance(true);
          setIsOverlayVisible(true);

          const pendingSignature = async (asyncResponseId: string) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
              method: "GET",
              headers: myHeaders,
            };

            try {
              const _result = await fetch(
                `${config.apiGateway.url}/checkPendingSignature/${asyncResponseId}/sas`,
                requestOptions
              );

              const resultJson = await _result.json();

              setResult((r) => {
                return {
                  ...r,
                  signature_serial: { serialNumber: resultJson?.serialNumber },
                };
              });
              setLoadingAssurance(undefined);
              setIsOverlayVisible(false);
            } catch (error) {
              setLoadingAssurance(undefined);
              setIsOverlayVisible(false);
            }
          };

          pendingSignature(asyncResponseId);
        } else if (
          event?.data === "sas-error-invalid-transaction-token" ||
          event?.data === "sas-error-user-cancelled" ||
          event?.data === "sas-error-authentication-failed"
        ) {
          setAsyncResponseId(null);
          setIframe(null);
          setLoadingAssurance(undefined);
          setIsOverlayVisible(false);
        }
      };

      window.addEventListener("message", handler);

      // clean up
      return () => window.removeEventListener("message", handler);
    }
  }, [asyncResponseId]);

  useEffect(() => {
    if (result !== null && result !== undefined) {
      if (result?.signature_api_response && !result?.signature_serial) {
        const getData = async () => {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          const requestOptions = {
            method: "GET",
            headers: myHeaders,
          };
          const _result = await fetch(
            `${config.apiGateway.url}/checkPendingSignature/${result.signature_api_response}/notSas`,
            requestOptions
          );

          const resultJson2 = await _result.json();

          setResult((r) => {
            return { ...r, signature_serial: resultJson2 };
          });
        };

        getData();
      } else {
        setIsOverlayVisible(false);

        if (result.loa?.statusCode === 404) {
          setShowAnswer("noEvidence");
        } else if (
          result.signature_serial?.consentUrl?.slice(0, 5) === "https"
        ) {
          setIframe(result.signature_serial.consentUrl);
          setAsyncResponseId(result.signature_serial.asyncResponseId);
        } else {
          // if the serial number is the same and starts with SAS
          if (
            result.loa?.serialNumber?.slice(0, 3) === "SAS" &&
            result.loa?.serialNumber === result?.signature_serial?.serialNumber
          ) {
            setShowAnswer("answer2");
          } else if (
            result.loa?.serialNumber?.slice(0, 3) === "SAS" &&
            result.signature_serial?.serialNumber?.slice(0, 3) === "SAS" &&
            result.signature_serial?.serialNumber !== result.loa?.serialNumber
          ) {
            setShowAnswer("answer5");
          } else if (
            result.loa.serialNumber === result.signature_serial.serialNumber &&
            result.loa.hasOwnProperty("serialNumber")
          ) {
            setShowAnswer("answer1");
          } else if (
            result.loa.serialNumber !== result.signature_serial?.serialNumber &&
            result.loa.serialNumber.slice(0, 3) === "SAS"
          ) {
            // if serial numbers are not the same
            // if the serial number starts with SAS
            setShowAnswer("answer3");
          } else if (
            // result.loa?.serialNumber !== result.signature_serial?.serialNumber &&
            result.signature_serial?.response
          ) {
            // if response contain cases like "userCanceled"
            setShowAnswer("aisError");
          } else {
            //different MID
            setShowAnswer("answer4");
          }
        }
      }
    }
  }, [result]);

  if (errorExist) {
    return (
      <div className="App-content">
        <Header />
        <div
          style={{
            display: "flex",
            minHeight: "calc(100vh - 140px)",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p>{t("technicalError")}</p>
          <sdx-button
            label={t("testAgain")}
            style={{
              marginTop: "30px",
              width: "100%",
              maxWidth: "400px",
              minWidth: "280px",
              padding: "8px",
            }}
            onClick={async () => {
              setResult(null);
              setShowAnswer("");
              setAsyncResponseId(null);
              setError(false);
              setIsOverlayVisible(false);
              setPhoneNumber("");
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="App-content">
      <Header />

      {!showAnswer && iframe === null && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 140px)",
            overflow: "scroll",
            marginBottom: "8px",
            paddingTop: "0px",
          }}
        >
          <img
            src={require("./assets/SC2021062900014.webp")}
            className="Header-image"
            width="100%"
            style={{ objectFit: "cover" }}
            alt="digital signature"
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minHeight: "calc(100vh - 321px)",
              marginBottom: "8px",
              paddingTop: "0px",
            }}
          >
            <NumberInput
              setIsOverlayVisible={setIsOverlayVisible}
              setResult={setResult}
              loadingAssurance={loadingAssurance}
              setLoadingAssurance={setLoadingAssurance}
              setPhoneNumber={setPhoneNumber}
              setError={setError}
            />
          </div>
        </div>
      )}

      {showAnswer && (
        <div
          className="animate__animated animate__fadeIn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "calc(100vh - 120px)",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              maxWidth: "900px",
              minWidth: "280px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <div className="animate__animated animate__fadeIn">
              <h2 className="text-h2" style={{ textAlign: "center" }}>
                {t("result")}
              </h2>
              <div style={{ marginBottom: "16px", justifyContent: "center" }}>
                <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
                  {phoneNumber}
                </p>
              </div>
            </div>

            {showAnswer === "answer1" && <Answer1 result={result} />}
            {showAnswer === "answer2" && <Answer2 result={result} />}
            {showAnswer === "answer3" && <Answer3 />}
            {showAnswer === "answer4" && <Answer4 />}
            {showAnswer === "answer5" && <Answer5 />}
            {showAnswer === "noEvidence" && <NoEvidence />}
            {showAnswer === "aisError" && <AisError result={result} />}

            <sdx-button
              label={t("testAgain")}
              style={{
                marginTop: "30px",
                width: "100%",
                maxWidth: "400px",
                minWidth: "280px",
                padding: "8px",
              }}
              onClick={async () => {
                setResult(null);
                setShowAnswer("");
                setAsyncResponseId(null);
              }}
            />
          </div>
        </div>
      )}

      {iframe && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 120px)",
            overflow: "scroll",
            marginBottom: "8px",
            paddingTop: "8px",
          }}
        >
          <iframe
            title="sas"
            style={{ width: "100%", height: "500px", border: "0" }}
            src={iframe}
          />
        </div>
      )}

      <Footer />
      {isOverlayVisible && <Overlay />}
    </div>
  );
}

export default App;
