import React, {useEffect, useRef} from "react";
import {WidgetInstance} from 'friendly-challenge';

const FriendlyCaptcha = ({siteKey, lang, onSuccess, onError}) => {
    const container = useRef<HTMLInputElement>(null);
    const widget = useRef<WidgetInstance>();

    useEffect(() => {
        if (!widget.current && container.current) {
            widget.current = new WidgetInstance(container.current, {
                startMode: "auto",
                language: lang,
                puzzleEndpoint: "https://eu-api.friendlycaptcha.eu/api/v1/puzzle",
                doneCallback: onSuccess,
                errorCallback: onError,
            });
        }

        return () => {
            if (widget.current != undefined) widget.current.reset();
        }
    }, [container]);

    return (
        <div ref={container} className="frc-captcha" data-sitekey={siteKey}/>
    );
}

export default FriendlyCaptcha;