import { useTranslation } from "react-i18next";
import { ReactComponent as MobileLogo } from "../assets/smartphone_BOLD.svg";

export const Overlay = () => {
  const { t } = useTranslation();

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{
        position: "fixed",
        display: "flex",
        backgroundColor: "rgba(0,0,0,0.6)",
        justifyContent: "center",
        alignItems: "center",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          backgroundColor: "#086ADB",

          margin: "16px",
          padding: "16px",
          borderRadius: "8px",
          maxWidth: "300px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MobileLogo width={"60px"} />
        <p
          style={{
            marginTop: "30px",
            whiteSpace: "pre-wrap",
            color: "#fff",
            fontWeight: "600",
          }}
        >
          {t("loadingButton")}
        </p>
      </div>
    </div>
  );
};
