import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "40px",
          alignItems: "center",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", marginLeft: "8px", marginRight: "8px" }}>
          <div>
            <a
              href={`https://trustservices.swisscom.com/${i18n.language}/swisscom-privacy-policy/`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <span>{t("footer.privacy")}</span>
            </a>
            <span
              className="separator"
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              |
            </span>
          </div>
          <div>
            <a
              href={`https://trustservices.swisscom.com/${i18n.language}/imprint/`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <span>{t("footer.imprint")}</span>
            </a>
          </div>
        </div>

        <div>
          <p
            className="copyright"
            style={{
              paddingLeft: "8px",
            }}
          >
            © Copyright Swisscom Trust Services {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};
