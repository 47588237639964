import i18next from "i18next";
import { forwardRef, memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "animate.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { config } from "../config";
import FriendlyCaptcha from "./FriendlyCaptcha";

export const NumberInput = ({
  setIsOverlayVisible,
  setResult,
  loadingAssurance,
  setLoadingAssurance,
  setPhoneNumber,
  setError,
}) => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);

  const [captchaValue, setCaptchaValue] = useState<string | null>("");
  const [number, setNumber] = useState<any>();

  const environment = process.env.REACT_APP_ENV;

  const handleChangeNumber = (event) => {
    setNumber(event);
    setPhoneNumber(event);
    setResult(null);
  };

  const asyncFunction = async (
    phoneNumber: string,
    environment: string | undefined
  ) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const body = JSON.stringify({
      captchaValue,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body,
    };

    try {
      const result = await fetch(
        `${
          config.apiGateway.url
        }/lookup/${phoneNumber}/${environment}/${i18next.language.slice(0, 2)}`,
        requestOptions
      );

      const resultJson = await result.json();

      setResult(resultJson);
    } catch (error) {
      setError(true);
    }
  };

  const onCaptchaChange = async (value: string | null) => {
    if (value) {
      setCaptchaValue(value);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const inputRef = useRef(null);

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "900px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="animate__animated animate__fadeIn"
        style={{
          margin: "16px",
        }}
      >
        <h2 className="text-h2">{t("title")}</h2>
        <div style={{ marginBottom: "16px" }}>
          <p style={{ whiteSpace: "pre-wrap" }}>{t("subtitle.text1")}</p>
          <ol className="list single-line">
            <li>{t("subtitle.list.text1")}</li>
            <li>{t("subtitle.list.text2")}</li>
            <li>{t("subtitle.list.text3")}</li>
          </ol>
          <p>{t("subtitle.text2")}</p>
        </div>
      </div>

      <div
        style={{
          maxWidth: "400px",
          minWidth: "280px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <form onSubmit={(event) => event.preventDefault()}>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: "0px",
              padding: "8px",
            }}
          >
            <PhoneInput
              ref={inputRef}
              placeholder={t("number")}
              value={number}
              onChange={handleChangeNumber}
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "30px",
              }}
              addInternationalOption={false}
              countryOptionsOrder={["CH", "DE", "FR", "IT", "AT"]}
              limitMaxLength
              autoComplete={"off"}
              inputComponent={InputComponent}
              className="text-h1"
              focusInputOnCountrySelection={false}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FriendlyCaptcha
              siteKey={config.captcha.siteKey}
              lang={i18next.language.slice(0, 2)}
              onSuccess={(solution) => onCaptchaChange(solution)}
              onError={(err) =>
                console.error(
                  `There was an error when trying to solve the Captcha.: ${err}`
                )
              }
            />
          </div>
          <sdx-button
            label={t("testNumber")}
            disabled={isDisabled || !isValidPhoneNumber(number ?? "")}
            style={{
              marginTop: "30px",
              width: "100%",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
            loading={loadingAssurance}
            onClick={async () => {
              setLoadingAssurance(true);
              setIsOverlayVisible(true);
              await asyncFunction(number.substring(1), environment);
              setLoadingAssurance(undefined);
            }}
          />
        </form>
        <p style={{ padding: "8px", fontSize: "16px" }}>{t("howItWorks")}</p>
      </div>
    </div>
  );
};

const _InputComponent = forwardRef((props, ref: any) => {
  return <input id="custom-input" ref={ref} {...props} />;
});

export const InputComponent = memo(_InputComponent);
