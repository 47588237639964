const configurationList = {
  local: {
    captcha: {
      siteKey: "FCMPIEIRHSB0I51J",
    },
    apiGateway: {
      url: "http://localhost:5001",
    },
  },
  dev: {
    captcha: {
      siteKey: "FCMPIEIRHSB0I51J",
    },
    apiGateway: {
      url: "https://check-signing-be.dev-scapp.swisscom.com",
    },
  },
  preprod: {
    captcha: {
      siteKey: "FCMPIEIRHUOQ4V3N",
    },
    apiGateway: {
      url: "https://check-signing-preprod-be.scapp.swisscom.com",
    },
  },
  prod: {
    captcha: {
      siteKey: "FCMPIEIRHVUHJGA5",
    },
    apiGateway: {
      url: "https://check-signature-be.scapp.swisscom.com",
    },
  },
};

const configuration = configurationList[process.env.REACT_APP_ENV ?? "dev"];

export const config = {
  // Add common config values here
  ...configuration,
};
