import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as FailureLogo } from "../assets/clear_circle_BOLD.svg";
import { ReactComponent as SuccessLogo } from "../assets/check_mark_2_circle_BOLD.svg";

const Success = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      margin: "8px",
    }}
  >
    <SuccessLogo width={"60px"} height={"60px"} />
  </div>
);

const Failure = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      margin: "8px",
    }}
  >
    <FailureLogo width={"60px"} height={"60px"} />
  </div>
);

export const AisError = ({ result }) => {
  const { t } = useTranslation();

  if ("userCanceled" === result.signature_serial.response) {
    return (
      <div>
        <Failure />

        <p style={{ whiteSpace: "pre-wrap" }}>
          {t(result.signature_serial.response)}
        </p>
      </div>
    );
  } else if (result.signature_serial.response === "pinBlocked") {
    return (
      <div>
        <Failure />

        <p style={{ whiteSpace: "pre-wrap" }}>{t("pinBlocked.part1")}</p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <u>{t("pinBlocked.part2")}</u>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <b>{t("pinBlocked.optionOne")}</b>
          {t("pinBlocked.optionOneText")}
        </p>

        <ol className="list single-line">
          <li>
            {t("pinBlocked.solution1.part1.text")}
            <a
              href={`https://www.mobileid.ch/${i18next.language}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("pinBlocked.solution1.part1.linkText")}
            </a>
          </li>
          <li>{t("pinBlocked.solution1.part2")}</li>
          <li>{t("pinBlocked.solution1.part3")}</li>
        </ol>

        <p style={{ whiteSpace: "pre-wrap" }}>
          <b>{t("pinBlocked.optionTwo.part1")}</b>
          {t("pinBlocked.optionTwo.part2")}
          <u>{t("pinBlocked.optionTwo.part3")}</u>
          {t("pinBlocked.optionTwo.part4")}
        </p>

        <ol className="list single-line">
          <li>
            {t("pinBlocked.solution2.part1.text")}
            <a
              href={`https://www.mobileid.ch/${i18next.language}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("pinBlocked.solution2.part1.linkText")}
            </a>
          </li>
          <li>{t("pinBlocked.solution2.part2")}</li>
          <li>{t("pinBlocked.solution2.part3")}</li>
        </ol>

        <p style={{ whiteSpace: "pre-wrap" }}>{t("pinBlocked.part3.text")}</p>

        <ul className="list single-line">
          <li>
            {t("pinBlocked.part3.solution1.text1")}
            <a
              href={`https://srsident.trustservices.swisscom.com/${i18next.language}/srs-direct/`}
              target="_blank"
              rel="noreferrer"
            >
              {t("pinBlocked.part3.solution1.linkText1")}
            </a>
            {t("pinBlocked.part3.solution1.text2")}
          </li>
          <li>{t("pinBlocked.part3.solution2")}</li>
          <li>
            {t("pinBlocked.part3.solution3.text1")}
            <a
              href={`https://srsident.trustservices.swisscom.com/${
                i18next.language === "en" ? "en" : ""
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {t("pinBlocked.part3.solution3.linkText1")}
            </a>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <Failure />

        <p style={{ whiteSpace: "pre-wrap" }}>
          {t(result.signature_serial.response)}
        </p>
      </div>
    );
  }
};

export const NoEvidence = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Failure />

      <p style={{ whiteSpace: "pre-wrap" }}>{t("noEvidence.part1")}</p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("noEvidence.part2")}</u>
      </p>
      <ul className="list single-line">
        <li>
          {t("answer3.part5.solution1.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${i18next.language}/srs-direct/`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer3.part5.solution1.linkText1")}
          </a>
          {t("answer3.part5.solution1.text2")}
        </li>
        <li>{t("answer3.part5.solution2")}</li>
        <li>
          {t("answer3.part5.solution3.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${
              i18next.language === "en" ? "en" : ""
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer3.part5.solution3.linkText1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Answer1 = ({ result }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Success />
      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer1.part1")}</p>
      <ul className="list single-line">
        {result.loa.assuranceLevel === 4 && <li>{t("answer1.qesCh")}</li>}
        {result.loa.assuranceLevelEidas === 4 && <li>{t("answer1.qesEu")}</li>}
        {result.loa.assuranceLevel === 4 && <li>{t("answer1.aesCh")}</li>}
        {result.loa.assuranceLevelEidas === 4 && <li>{t("answer1.aesEu")}</li>}
        {result.loa.assuranceLevel === 3 && <li>{t("answer1.aesCh")}</li>}
        {result.loa.assuranceLevelEidas === 3 && <li>{t("answer1.aesEu")}</li>}
      </ul>

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer1.definition")}</p>
    </div>
  );
};

export const Answer2 = ({ result }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Success />
      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer2.part1")}</p>
      <ul className="list single-line">
        {result.loa.assuranceLevel === 4 && <li>{t("answer2.qesCh")}</li>}
        {result.loa.assuranceLevelEidas === 4 && <li>{t("answer2.qesEu")}</li>}
        {result.loa.assuranceLevel === 4 && <li>{t("answer2.aesCh")}</li>}
        {result.loa.assuranceLevelEidas === 4 && <li>{t("answer2.aesEu")}</li>}
        {result.loa.assuranceLevel === 3 && <li>{t("answer2.aesCh")}</li>}
        {result.loa.assuranceLevelEidas === 3 && <li>{t("answer2.aesEu")}</li>}
      </ul>

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer2.definition")}</p>
    </div>
  );
};
export const Answer3 = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Failure />

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer3.part1")}</p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer3.part2")}</u>
      </p>

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer3.part3")}</p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer3.part4")}</u>
      </p>

      <ul className="list single-line">
        <li>
          {t("answer3.part5.solution1.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${i18next.language}/srs-direct/`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer3.part5.solution1.linkText1")}
          </a>
          {t("answer3.part5.solution1.text2")}
        </li>
        <li>{t("answer3.part5.solution2")}</li>
        <li>
          {t("answer3.part5.solution3.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${
              i18next.language === "en" ? "en" : ""
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer3.part5.solution3.linkText1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Answer4 = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Failure />

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer4.part1")}</p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer4.part2")}</u>
      </p>

      <ol className="list single-line">
        <li>
          {t("answer4.part3.solution1.text1")}
          <a
            href={`https://www.mobileid.ch/${i18next.language}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer4.part3.solution1.linkText1")}
          </a>
          {t("answer4.part3.solution1.text2")}
          <a
            href={`https://www.mobileid.ch/${i18next.language}/faq`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer4.part3.solution1.linkText2")}
          </a>
        </li>
        <li>{t("answer4.part3.solution2")}</li>
      </ol>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer4.part4")}</u>
      </p>

      <ul className="list single-line">
        <li>
          {t("answer4.part5.solution1.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${i18next.language}/srs-direct/`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer4.part5.solution1.linkText1")}
          </a>
          {t("answer4.part5.solution1.text2")}
        </li>
        <li>{t("answer4.part5.solution2")}</li>
        <li>
          {t("answer4.part5.solution3.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${
              i18next.language === "en" ? "en" : ""
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer4.part5.solution3.linkText1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Answer5 = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Failure />

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer5.part1")}</p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer5.part2")}</u>
      </p>

      <p style={{ whiteSpace: "pre-wrap" }}>{t("answer5.part3")}</p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <u>{t("answer5.part4")}</u>
      </p>

      <ul className="list single-line">
        <li>
          {t("answer5.part5.solution1.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${i18next.language}/srs-direct/`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer5.part5.solution1.linkText1")}
          </a>
          {t("answer5.part5.solution1.text2")}
        </li>
        <li>{t("answer5.part5.solution2")}</li>
        <li>
          {t("answer5.part5.solution3.text1")}
          <a
            href={`https://srsident.trustservices.swisscom.com/${
              i18next.language === "en" ? "en" : ""
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer5.part5.solution3.linkText1")}
          </a>
        </li>
      </ul>

      <p style={{ whiteSpace: "pre-wrap" }}>
        <b>{t("answer5.hint")}</b>
        {t("answer5.part6")}
      </p>

      <ol className="list single-line">
        <li>
          {t("answer5.part7.text1")}
          <a
            href={`https://www.mobileid.ch/${i18next.language}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("answer5.part7.linkText1")}
          </a>
        </li>
        <li>{t("answer5.part7.text2")}</li>
        <li>{t("answer5.part7.text3")}</li>
        <li>{t("answer5.part7.text4")}</li>
      </ol>
    </div>
  );
};
