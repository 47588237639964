import SpriteLogo from "../assets/lifeform-spritesheet@2x.png";
import { SpriteAnimator } from "react-sprite-animator";
import { ReactComponent as SwisscomLogoText } from "../assets/swisscom-logo-type.svg";
import i18n from "../i18n";

export const Header = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: "8px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SpriteAnimator
            sprite={SpriteLogo}
            width={80}
            height={80}
            fps={16}
            scale={2}
          />
          <SwisscomLogoText />
        </div>
        <sdx-select onInput={(e) => changeLanguage(e.currentTarget.value[0])}>
          <sdx-select-option value="en" selected={i18n.language === "en"}>
            EN
          </sdx-select-option>
          <sdx-select-option value="de" selected={i18n.language === "de"}>
            DE
          </sdx-select-option>
        </sdx-select>
      </div>
      <div style={{ height: 1, backgroundColor: "#DFE3E7" }} />
    </div>
  );
};
